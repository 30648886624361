<template>
  <v-app>
    <v-app>
    <v-banner
        class="lavBanner" 
        single-line
        height="300"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/AICase-banner.png"
          height="100%"
          class="d-flex align-center"
        >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex justify-center">
          <v-card color="transparent" flat width="80%">
            <div class="d-flex flex-no-wrap justify-center" style="color: #fff">
              <div>
                <v-card-title class="white--text font-size-banner-title font-weight-bold px-0">
                  技术+创新+应用，AI引领未来
                </v-card-title>
                <v-card-title class="white--text font-size-banner-tip px-0" style="line-height:30px;margin:0 auto;text-align: center;display: block;">
                  强大可靠的技术后盾，为企业提供优质易用的应用方案，帮助客户与伙伴成功
                </v-card-title>
                <!-- <div class="d-flex justify-center mt-5">
                  <v-btn
                    width="160"
                    class="font-size-20 rounded-pill"
                    color="#0084ff"
                    style="color: #fff"
                  >
                    立即体验
                  </v-btn>
                </div> -->
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <v-container class="mt-7" style="margin:0 auto">
        <v-tabs v-model="tab" centered color="#0084ff" class="g-tabs new-tabs-two" >
          <v-tab v-for="(item,index) in data" :key="index">
            <v-card-title class="font-size-16" :style="{'color':tab == index ? '#0084ff!important':''}">{{ item.title }}</v-card-title>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item v-for="(item,index) in data" :key="index" style="margin:0 auto;">
                <v-row v-show="tab === index" style="margin:30px auto 0;width:1120px">
                    <v-col v-for="(info,infoIndex) in item.tabItem" :key="infoIndex" cols="3">
                        <v-card flat
                            class="mx-auto item-card "
                            max-width="300"
                            max-height="300"
                            @click="goCaseDetail(info)"
                        >
                            <v-img
                                width="260"
                                max-height="200"
                                class="tran-sec"
                                :src="info.coverImg"
                                :aspect-ratio="9/16"
                            >
                            </v-img>
                            <div class="text-box">
                              <v-card-text class="font-size-14 sizeStyle" v-text="info.itemTitle"></v-card-text>
                              <v-card-text class="font-size-14 sizeStyle" style="margin-top:-8px;color: #666666;" v-text="info.publicationTime"></v-card-text>
                              <!-- <v-card-text class="font-size-16 sizeStyle">今年新增37个本科专业 智能采矿、<br/>智慧交通等上榜</v-card-text> -->
                              <!-- <v-card-text class="font-size-14 sizeStyle" style="margin-top:-8px;color: #666666;">2021/06/28</v-card-text> -->
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-tab-item>
        </v-tabs-items>
    </v-container>
  </v-app>
  </v-app>
</template>

<script>
import lavData from '@/static/lavData.js';
import qs from 'qs'
export default {
  name: "AIcase",
  data() {
    return {
        imgLoad: false,
        data: lavData.AICase,
        tab: null,
        showDialog: false
    }
  },
  mounted(){
    
  },
  methods: {
    goCaseDetail:(item) =>{
      let query = {
        source: 'qita',
        anli: item.self
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.lavBanner {
  border: none !important;
  ::v-deep .v-banner__wrapper,
  ::v-deep .v-banner__content,
  ::v-deep .v-banner__text {
    padding: 0 !important;
    height: 100%;
  }
}
.item-card {
  margin-bottom: 15px;
  //margin-top: 10px;
  padding: 10px;
  border-radius: 8px;
  transition: 0.3s;
  .v-image__image, .v-image__placeholder,.theme--light.v-image{
    object-fit: contain;
    width: 260px;
    height: 180px;
    border-radius: 10px;
  }
  &:hover {
    margin-top: 10px;
    box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
    transform: scale(1.04);
  }
  &:focus{
    display: none;
  }
}
// .v-image__image, .v-image__placeholder,.theme--light.v-image{
//     margin: 0 auto;
//     width: 260px;
//     padding: 0;
//     //margin: 0px;
//     border-radius: 10px;
//     transition: 0.3s;
//     &:hover {
//       transform: scale(1.08);
//      }
//   }
::v-deep.sizeStyle{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 0px !important;
}
::v-deep .col-3{
  height: 320px !important;
}
::v-deep .theme--light.v-tabs-items{
  background: #f5f8fd !important; 
}
::v-deep .new-tabs-two{
  height: 70px !important;
  padding: 0 !important;
}
::v-deep .new-tabs-two .v-tabs-bar__content{
  padding: 0 !important;
}
::v-deep .theme--light.v-card,.theme--light.v-sheet{
  background-color:unset; 
  border-color: unset;
}
</style>
